import React, { useEffect } from "react";
import { Button } from "@mui/material";
import { useStoreAddress } from "../hooks/useStoreAddress";
import {
    AddressField,
    StateSelect,
    MunicipalitySelect,
    NeighborhoodSelect,
} from "./AddressFields";

const StoreAddress = ({
    register,
    errors,
    dataRowEdit,
    responseZipCode,
    handleZipcodeSearch,
    setValue,
    handleNeighborhoodChange,
    statesData,
    handleStateSelect,
    municipalities,
    handleMunicipalitySelect,
    ResponseNeighborhood,
    handleNeighborhoodSelect,
    cleanMunicipalityClean,
    cleanNeighborhoodClean,
    fetchNeighborhoodLocation,
    responseNeighborhoodLocation,
    cleanMunicipality,
    cleanNeighborhood,
}) => {
    const responseNeighborhood = responseNeighborhoodLocation?.payload;

    const {
        state,
        municipality,
        setMunicipality,
        setNeighborhood,
        neighborhood,
        handleStateChange,
        handleMunicipalityChange,
        handleNeighborhoodChange: handleNeighborhoodChangeLocal,
    } = useStoreAddress({ setValue, responseZipCode, dataRowEdit });

    useEffect(() => {
        if (dataRowEdit) {
            setValue("postalCode", dataRowEdit.zipcode);
            setValue("state", dataRowEdit.state);
            setValue("municipality", dataRowEdit.municipality);
            setValue("neighborhood", dataRowEdit.neighborhood);
            setValue("street", dataRowEdit.address);
            setValue("reference", dataRowEdit.address_reference);
        }
    }, [dataRowEdit, setValue]);

    useEffect(() => {
        if (responseZipCode) {
            setValue("state", responseZipCode.state);
            setValue("municipality", responseZipCode.municipality);
            setValue("neighborhood", responseZipCode.neighborhoods?.[0]?.name);
        }
    }, [responseZipCode, setValue]);

    useEffect(() => {
        if (neighborhood.length > 0) {
            const selectedNeighborhood = ResponseNeighborhood.find(
                (n) => n.name === neighborhood
            );
            if (
                selectedNeighborhood &&
                selectedNeighborhood.latitude === null &&
                selectedNeighborhood.longitude === null
            ) {
                fetchNeighborhoodLocation(selectedNeighborhood.id);
            }
        }
    }, [neighborhood, ResponseNeighborhood, fetchNeighborhoodLocation]);

    useEffect(() => {
        if (responseNeighborhoodLocation) {
            const { lat, lng, neighborhood_id } = responseNeighborhoodLocation;
            setValue("latitude", lat);
            setValue("longitude", lng);
        }
    }, [responseNeighborhoodLocation, setValue]);

    const handleSearchAndClear = () => {
        cleanMunicipality();
        handleZipcodeSearch();
        cleanNeighborhood();
    };

    const handleStateChangeAndClear = (event) => {
        handleStateChange(event);
        setValue("municipality", "");
        setValue("neighborhood", "");
        setMunicipality("");
        setNeighborhood("");
    };

    return (
        <>
            <AddressField
                label="Código postal"
                defaultValue={dataRowEdit?.zipcode}
                register={register("postalCode", {
                    required: "Código postal es obligatorio",
                })}
                error={errors.postalCode}
                helperText={errors.postalCode?.message}
            />
            <Button
                onClick={handleSearchAndClear}
                variant="contained"
                color="secondary"
                fullWidth
                sx={{ backgroundColor: "#AD6C56", mt: 1, mb: 2 }}
            >
                Buscar
            </Button>
            <StateSelect
                state={state}
                statesData={statesData}
                handleStateChangeAndClear={handleStateChangeAndClear}
                handleStateSelect={handleStateSelect}
                register={register("state", {
                    required: "Estado es obligatorio",
                })}
                error={errors.state}
                dataRowEdit={dataRowEdit}
            />
            <MunicipalitySelect
                dataRowEdit={dataRowEdit}
                municipality={municipality}
                municipalities={municipalities}
                handleMunicipalityChange={handleMunicipalityChange}
                handleMunicipalitySelect={handleMunicipalitySelect}
                responseZipCode={responseZipCode}
                register={register("municipality", {
                    required: "Municipio es obligatorio",
                })}
                error={errors.municipality}
            />
            <NeighborhoodSelect
                neighborhood={neighborhood}
                ResponseNeighborhood={ResponseNeighborhood}
                handleNeighborhoodChangeLocal={handleNeighborhoodChangeLocal}
                handleNeighborhoodSelect={handleNeighborhoodSelect}
                responseZipCode={responseZipCode}
                register={register("neighborhood", {
                    required: "Colonia es obligatoria",
                })}
                error={errors.neighborhood}
                dataRowEdit={dataRowEdit}
            />
            <AddressField
                label="Calle y número"
                defaultValue={dataRowEdit?.address}
                register={register("street", {
                    required: "Calle y número son obligatorios",
                })}
                error={errors.street}
                helperText={errors.street?.message}
            />
            <AddressField
                label="Referencia"
                defaultValue={dataRowEdit?.address_reference}
                register={register("reference", {
                    required: "Referencia es obligatoria",
                })}
                error={errors.reference}
                helperText={errors.reference?.message}
            />
        </>
    );
};

export default StoreAddress;
