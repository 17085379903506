export const CircularProgressStyle = {
    CircularProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
    },
};

export const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "95%", md: 450 },
    bgcolor: "white",
    border: "2px solid #000",
    boxShadow: 24,
    p: { xs: 0, md: 4 },
};

export const butttonStyle = {
    backgroundColor: "#BC955C",
    borderRadius: "10px",
    color: "white",
    textTransform: "none",
    px: 3,
    "&:hover": {
        backgroundColor: "#a68a60",
    },

    "&.Mui-disabled": {
        backgroundColor: "#BC955C",
        opacity: 0.8,
        color: "white",
    },
};

// styles.js
export const buttonStyle = {
    backgroundColor: "#AD6C56",
};

export const loadingStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 9999,
};

export const circularProgressStyle = {
    color: "#fff",
};

export const getFlexEndStyle = () => ({
    display: "flex",
    justifyContent: "flex-end",
});
