import React from "react";
import {
    Button,
    CircularProgress,
    Switch,
    FormControlLabel,
} from "@mui/material";
import StoreDetails from "./StoreDetails";
import StoreContact from "./StoreContact";
import { Schedule } from "./schedule/Schedule";
import { StoreImageUpload } from "./StoreImageUpload";
import StoreAddress from "./StoreAddress";
import StoreMap from "utils/StoreMap";
import Dialogs from "./Dialogs";
import statesData from "../../../utils/cat_sepomex_states.json";
import { useMapLocation } from "../hooks/useMapLocation";
import { getFlexEndStyle } from "../style/style";
const AddStoreForm = ({
    initialValues,
    register,
    handleSubmit,
    errors,
    setFile,
    setOpeningHours,
    setValue,
    trigger,
    openingHours,
    loading,
    responseMessage,
    handleSearchStore,
    omvs,
    is_registered,
    dialogOpen,
    setDialogOpen,
    successDialogOpen,
    setSuccessDialogOpen,
    storeSearched,
    setStoreSearched,
    handleFormSubmit,
    handleZipcodeSearch,
    responseZipCode,
    handleOmvChange,
    selectedOmv,
    warningsDialogOpen,
    setWarningsDialogOpen,
    toggleState,
    handleToggleChange,
    dataRowEdit,
    setdataRowEdit,
    cleanIsEditStores,
    handleClose,
    cleanIsRegisteredStores,
    municipalityAction,
    neighborhoodAction,
    municipality,
    neighborhood,
    fetchNeighborhoodLocation,
    responseNeighborhoodLocation,
    cleanMunicipality,
    cleanNeighborhood,
}) => {
    const responseMunicipality = municipality?.payload?.municipalities;
    const responseNeighborhood = neighborhood?.payload?.neighborhoods;
    const {
        mapLocation,
        handleLocationChange,
        handleStateSelect,
        handleMunicipalitySelect,
        handleNeighborhoodSelect,
    } = useMapLocation({
        responseZipCode,
        responseMunicipality,
        statesData,
        municipalityAction,
        neighborhoodAction,
        responseNeighborhoodLocation,
    });
    return (
        <>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
                {!initialValues && (
                    <StoreDetails
                        handleOmvChange={handleOmvChange}
                        selectedOmv={selectedOmv}
                        loading={loading}
                        omvs={omvs}
                        register={register}
                        errors={errors}
                        handleSearchStore={() => {
                            handleSearchStore();
                            setStoreSearched(true);
                        }}
                        setStoreSearched={setStoreSearched}
                    />
                )}
                {(initialValues ||
                    (is_registered !== true &&
                        is_registered !== undefined &&
                        storeSearched)) && (
                    <>
                        <div style={getFlexEndStyle()}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={toggleState}
                                        onChange={handleToggleChange}
                                        color="primary"
                                    />
                                }
                                label="Tienda Publicada"
                            />
                        </div>
                        <StoreContact
                            register={register}
                            errors={errors}
                            dataRowEdit={dataRowEdit}
                        />
                        <Schedule
                            setValue={setValue}
                            setOpeningHours={setOpeningHours}
                            openingHours={openingHours}
                            dataRowEdit={dataRowEdit}
                            setdataRowEdit={setdataRowEdit}
                        />
                        <StoreImageUpload
                            trigger={trigger}
                            errors={errors}
                            register={register}
                            setFile={setFile}
                            setValue={setValue}
                            dataRowEdit={dataRowEdit}
                        />
                        <StoreAddress
                            responseNeighborhoodLocation={
                                responseNeighborhoodLocation
                            }
                            fetchNeighborhoodLocation={
                                fetchNeighborhoodLocation
                            }
                            cleanNeighborhood={cleanNeighborhood}
                            cleanMunicipality={cleanMunicipality}
                            statesData={statesData}
                            setValue={setValue}
                            loading={loading}
                            responseZipCode={responseZipCode}
                            handleZipcodeSearch={handleZipcodeSearch}
                            register={register}
                            errors={errors}
                            dataRowEdit={dataRowEdit}
                            handleLocationChange={handleLocationChange}
                            handleStateSelect={handleStateSelect}
                            handleMunicipalitySelect={handleMunicipalitySelect}
                            handleNeighborhoodSelect={handleNeighborhoodSelect}
                            municipalities={responseMunicipality || []}
                            ResponseNeighborhood={responseNeighborhood || []}
                        />
                        <StoreMap
                            latitude={mapLocation.latitude}
                            longitude={mapLocation.longitude}
                            onLocationChange={handleLocationChange}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            fullWidth
                        >
                            {initialValues
                                ? "Editar tienda"
                                : "Registrar tienda"}
                        </Button>
                        {loading && (
                            <div>
                                <CircularProgress />
                            </div>
                        )}
                    </>
                )}
            </form>
            <Dialogs
                cleanIsRegisteredStores={cleanIsRegisteredStores}
                cleanIsEditStores={cleanIsEditStores}
                setWarningsDialogOpen={setWarningsDialogOpen}
                warningsDialogOpen={warningsDialogOpen}
                handleClose={handleClose}
                dialogOpen={dialogOpen}
                setDialogOpen={setDialogOpen}
                successDialogOpen={successDialogOpen}
                setSuccessDialogOpen={setSuccessDialogOpen}
                responseMessage={responseMessage}
            />
        </>
    );
};
export default AddStoreForm;
