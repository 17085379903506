import { useState, useEffect } from "react";

export const useMapLocation = ({
    responseZipCode,
    responseMunicipality,
    statesData,
    municipalityAction,
    neighborhoodAction,
    responseNeighborhoodLocation,
}) => {
    const [mapLocation, setMapLocation] = useState({
        latitude: null,
        longitude: null,
    });
    useEffect(() => {
        if (
            responseZipCode &&
            responseZipCode.neighborhoods &&
            responseZipCode.neighborhoods.length > 0
        ) {
            const { latitude, longitude } = responseZipCode.neighborhoods[0];
            setMapLocation({ latitude, longitude });
        }
    }, [responseZipCode]);

    useEffect(() => {
        if (responseNeighborhoodLocation) {
            const { payload } = responseNeighborhoodLocation;
            const { lat, lng } = payload || {};
            setMapLocation({ latitude: lat, longitude: lng });
        }
    }, [responseNeighborhoodLocation]);

    const handleLocationChange = (latitude, longitude) => {
        setMapLocation({ latitude, longitude });
    };

    const handleStateSelect = (stateId) => {
        const selectedState = statesData.find((state) => state.id === stateId);
        if (selectedState) {
            setMapLocation({
                latitude: selectedState.latitude,
                longitude: selectedState.longitude,
            });
        }
        municipalityAction({ state_id: stateId });
    };

    const handleMunicipalitySelect = (municipalityId) => {
        const selectedMunicipality = responseMunicipality.find(
            (municipality) => municipality.id === municipalityId
        );
        if (selectedMunicipality) {
            setMapLocation({
                latitude: selectedMunicipality.latitude,
                longitude: selectedMunicipality.longitude,
            });
        }
        neighborhoodAction({ municipality_id: municipalityId });
    };

    const handleNeighborhoodSelect = (neighborhood) => {
        if (neighborhood && neighborhood.latitude && neighborhood.longitude) {
            const { latitude, longitude } = neighborhood;
            setMapLocation({ latitude, longitude });
        }
    };

    return {
        mapLocation,
        handleLocationChange,
        handleStateSelect,
        handleMunicipalitySelect,
        handleNeighborhoodSelect,
    };
};
