import React from "react";
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material";
import { useSelect } from "../hooks/useSelect";

export const AddressField = ({
    label,
    defaultValue,
    register,
    error,
    helperText,
}) => (
    <TextField
        label={label}
        variant="outlined"
        fullWidth
        margin="normal"
        defaultValue={defaultValue}
        {...register}
        error={Boolean(error)}
        helperText={helperText}
        sx={{ backgroundColor: "#fff", borderRadius: "4px" }}
    />
);

export const StateSelect = ({
    state,
    statesData,
    handleStateChangeAndClear,
    handleStateSelect,
    register,
    error,
    dataRowEdit,
}) => {
    useSelect(dataRowEdit?.state, statesData, handleStateSelect);

    return (
        <FormControl fullWidth margin="normal" error={Boolean(error)}>
            <InputLabel>Estado</InputLabel>
            <Select
                {...register}
                variant="outlined"
                value={state || ""}
                onChange={(event) => {
                    handleStateChangeAndClear(event);
                    const selectedState = statesData.find(
                        (state) => state.name === event.target.value
                    );
                    handleStateSelect(selectedState.id);
                }}
                sx={{ backgroundColor: "#fff", borderRadius: "4px" }}
            >
                {statesData.map((state) => (
                    <MenuItem key={state.id} value={state.name}>
                        {state.name}
                    </MenuItem>
                ))}
            </Select>
            {error && <p style={{ color: "red" }}>{error.message}</p>}
        </FormControl>
    );
};

export const MunicipalitySelect = ({
    municipality,
    municipalities,
    handleMunicipalityChange,
    handleMunicipalitySelect,
    responseZipCode,
    register,
    error,
    dataRowEdit,
}) => {
    useSelect(
        dataRowEdit?.municipality,
        municipalities,
        handleMunicipalitySelect
    );

    return (
        <FormControl fullWidth margin="normal" error={Boolean(error)}>
            <InputLabel>Municipio</InputLabel>
            <Select
                {...register}
                variant="outlined"
                value={municipality || ""}
                onChange={(event) => {
                    handleMunicipalityChange(event);
                    const selectedMunicipality = municipalities.find(
                        (municipality) =>
                            municipality.name === event.target.value
                    );
                    handleMunicipalitySelect(selectedMunicipality.id);
                }}
                sx={{ backgroundColor: "#fff", borderRadius: "4px" }}
            >
                {municipalities && municipalities.length > 0 ? (
                    municipalities.map((municipality) => (
                        <MenuItem
                            key={municipality?.id}
                            value={
                                municipality?.name ||
                                responseZipCode?.municipality
                            }
                        >
                            {municipality?.name ||
                                responseZipCode?.municipality}
                        </MenuItem>
                    ))
                ) : (
                    <MenuItem value={responseZipCode?.municipality}>
                        {responseZipCode?.municipality}
                    </MenuItem>
                )}
            </Select>
            {error && <p style={{ color: "red" }}>{error.message}</p>}
        </FormControl>
    );
};

export const NeighborhoodSelect = ({
    neighborhood,
    ResponseNeighborhood,
    handleNeighborhoodChangeLocal,
    handleNeighborhoodSelect,
    responseZipCode,
    register,
    error,
    dataRowEdit,
}) => {
    useSelect(
        dataRowEdit?.neighborhood,
        ResponseNeighborhood,
        handleNeighborhoodSelect
    );

    return (
        <FormControl fullWidth margin="normal" error={Boolean(error)}>
            <InputLabel>Colonia</InputLabel>
            <Select
                {...register}
                variant="outlined"
                value={neighborhood || ""}
                onChange={(event) => {
                    const selectedNeighborhood = ResponseNeighborhood.find(
                        (neigh) => neigh.name === event.target.value
                    );
                    handleNeighborhoodChangeLocal(event);
                    handleNeighborhoodSelect(selectedNeighborhood);
                }}
                sx={{ backgroundColor: "#fff", borderRadius: "4px" }}
            >
                {ResponseNeighborhood && ResponseNeighborhood.length > 0
                    ? ResponseNeighborhood.map((neigh) => (
                          <MenuItem key={neigh.id} value={neigh.name}>
                              {neigh.name}
                          </MenuItem>
                      ))
                    : responseZipCode?.neighborhoods?.map((neigh) => (
                          <MenuItem key={neigh.id} value={neigh.name}>
                              {neigh.name}
                          </MenuItem>
                      ))}
            </Select>
            {error && <p style={{ color: "red" }}>{error.message}</p>}
        </FormControl>
    );
};
